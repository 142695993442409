import { loadScript } from '@sas/ltjs-commons/util/ltjsLoaderUtil';
const customElementCSS = `
sas-report, sas-report-object, sas-report-page {
  display: block;
}

sas-report {
  min-width: 50px;
  min-height: 50px;
}
`;
const currentScript = document.currentScript;
if (currentScript instanceof HTMLScriptElement) {
    // Generate css for custom elements
    {
        const style = document.createElement('style');
        style.appendChild(document.createTextNode(customElementCSS));
        document.head.appendChild(style);
    }
    // Get the the base path for the va-sdk-loader.js script
    let scriptPath = currentScript.src.replace(/va-report-components.js$/, '');
    if (!scriptPath.endsWith('/')) {
        scriptPath += '/';
    }
    //
    // Load LTJS
    // Set the ltjslibpath relative to this loader script.
    // sas.ltjs is initialized as part of the ltjsLoaderUtil
    //
    window.sas.ltjs.resourceUrl = scriptPath + 'assets/';
    loadScript([]);
    // Load the library
    {
        const script = document.createElement('script');
        script.async = true;
        script.src = `${scriptPath}va-report-components-lib.js`;
        currentScript.appendChild(script);
    }
    // Load the css
    {
        const vaCss = document.createElement('link');
        vaCss.rel = 'stylesheet';
        vaCss.href = `${scriptPath}va-report-components-lib.css`;
        document.head.appendChild(vaCss);
    }
}
else {
    // TODO: align this with documentation/getting-started and maybe mention the
    //     | currentScript limitations in an expanded trouble shooting guide.
    console.error('va-sdk:ERROR: va-report-components.js must have its own script tag.');
}
